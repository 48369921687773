import React, { useState } from "react"
import { Link } from "gatsby"

const Stories = ({ stories }) => {
  const regularStories = stories.filter(s => !s.node.storyCollection)
  const collectionStories = stories.filter(s => s.node.storyCollection)

  const [showStoryCollections, setShowStoryCollections] = useState(false)

  return (
    <div className="main main-stories" style={{ maxWidth: "1100px" }}>
      <div className="stories-carousel">
        <div className="stories">
          {regularStories.map(story => (
            <div className="story-container">
              <Link
                className="story"
                style={{
                  background: `url(${story.node.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderColor: story.node.color,
                }}
                to={`/pt/stories/${story.node.slug}`}
              ></Link>
            </div>
          ))}
          <div className="story-container">
            <button
              className="story-show-collections"
              onClick={() => setShowStoryCollections(!showStoryCollections)}
            >
              {showStoryCollections ? <span>&#45;</span> : <span>&#43;</span>}
            </button>
          </div>
        </div>
      </div>

      <div className="stories-carousel">
        {showStoryCollections && (
          <div className="stories-carousel">
            <div className="stories" style={{ marginTop: 20 }}>
              {collectionStories.map(story => (
                <div className="story-container">
                  <Link
                    className="story"
                    style={{
                      background: `url(${story.node.imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderColor: story.node.color,
                    }}
                    to={`/pt/stories/${story.node.slug}`}
                  ></Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Stories
