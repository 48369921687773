import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import handleMetaDescription from "../utils/handleMetaDescription"

import articleSchema from "../schemas/article"

import nizamLogo from "../images/nizam.png"

function SEO({
  description,
  lang,
  meta,
  title,
  card = false,
  image,
  canonical,
  date,
  dateUpdated,
  category,
  isAlternate,
  alternateUrl,
}) {
  let metaDescription =
    description ||
    "O Nizam é um aplicativo gratuito onde você pode aprender um pouco mais sobre o Islam todos os dias"
  metaDescription = handleMetaDescription(metaDescription)

  image = image || "https://nizam.app/nizam-logo.png"

  const defaultTitle = "Nizam"

  let linkTagsArray = [{ rel: "canonical", href: canonical }]

  if (isAlternate) {
    linkTagsArray = [
      { rel: "canonical", href: canonical },
      { rel: "alternate", hrefLang: "en", href: alternateUrl },
    ]
  }

  if (card) {
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        link={linkTagsArray}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: canonical,
          },
          {
            property: `og:locale`,
            content: "pt_BR",
          },
          {
            property: `og:site_name`,
            content: "Nizam",
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: image,
          },
          {
            property: `og:image:secure_url`,
            content: image,
          },
          {
            property: `og:image:width`,
            content: 1200,
          },
          {
            property: `og:image:height`,
            content: 800,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: "Nizam" || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {JSON.stringify(
            articleSchema(
              canonical,
              title,
              image,
              date,
              dateUpdated,
              metaDescription,
              category
            )
          )}
        </script>
      </Helmet>
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={linkTagsArray}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: "Nizam",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image || nizamLogo,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "Nizam" || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
