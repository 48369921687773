import React from "react"
import { Link, graphql } from "gatsby"
import chroma from "chroma-js"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Breadcrumbs from "../../components/Breadcrumbs"

import "./Pages.css"

const Topics = ({ data }) => {
  const topics = data.allTopicsPt.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )

  return (
    <Layout>
      <SEO
        title="Tópicos"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/topics`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/topics`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Tópicos"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Minutos de sabedoria no seu tempo livre</h1>
            <p>
              Acesse os melhores conteúdos sobre espiritualidade, história e
              sabedorias islâmicas. Explore nossos tópicos:
            </p>
          </div>

          <div className="topics-list">
            {topics.map(topic => (
              <Link
                to={`/pt/topics/${topic.node.slug}`}
                className="topic-item"
                style={{
                  background: `linear-gradient(0deg, ${chroma(
                    topic.node.color
                  ).brighten(0.2)} 35%, ${topic.node.color} 35%)`,
                }}
              >
                <div className="topic-item-info">
                  <h3>{topic.node.name}</h3>
                  <img src={topic.node.imageUrl} />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Topics

export const query = graphql`
  query {
    allTopicsPt {
      edges {
        node {
          name
          slug
          color
          imageUrl
          createdAt
        }
      }
    }
  }
`
