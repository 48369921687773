import React, { useRef } from "react"
import { navigate } from "gatsby"

import SearchIcon from "../images/icons/Search"

import "./Search.css"

const MobileSearch = ({ toggleSearch, setToggleSearch }) => {
  const queryInput = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()
    console.log(queryInput.current.value)

    navigate(`/pt/search?s=${queryInput.current.value}`, {
      state: { query: queryInput.current.value },
      replace: true,
    })
  }

  return (
    <div
      className="mobile-search"
      style={{
        display: toggleSearch ? "flex" : "none",
        zIndex: toggleSearch ? "9999" : "99",
      }}
    >
      <SearchIcon />

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="query"
          ref={queryInput}
          id="search-text-input"
          placeholder="Encontre uma leitura..."
          autoFocus
        />
        <span
          className="search-close-btn"
          onClick={() => setToggleSearch(!toggleSearch)}
        >
          &times;
        </span>
      </form>
    </div>
  )
}

export default MobileSearch
