import React from "react"
import { Link } from "gatsby"

import { TelegramIcon } from "react-share"

import HomeIcon from "../images/icons/Home"
import FeedIcon from "../images/icons/Feed"
import TopicsIcon from "../images/icons/Topics"
import CollectionsIcon from "../images/icons/Collections"
import TrendIcon from "../images/icons/Trend"
import HashtagIcon from "../images/icons/Hashtag"
import FacebookIcon from "../images/icons/Facebook"
import InstagramIcon from "../images/icons/Instagram"
import MadeWithIcon from "../images/icons/MadeWith"

import Stories from "./Stories"

import logoImage from "../images/nizam.png"

import "./Navigation.css"

const Navigation = ({ stories }) => {
  return (
    <aside className="desktop-aside">
      <Link to="/pt" className="menu-logo" partiallyActive={true}>
        <img src={logoImage} alt="Nizam" />
      </Link>

      <nav>
        <Link
          to="/pt"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <HomeIcon />
          </div>
          <span>Início</span>
        </Link>

        <Link
          to="/pt/cards"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon">
            <FeedIcon />
          </div>
          <span>Feed</span>
        </Link>

        <Link
          to="/pt/topics"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <TopicsIcon />
          </div>
          <span>Tópicos</span>
        </Link>

        <Link
          to="/pt/collections"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <CollectionsIcon />
          </div>
          <span>Coleções</span>
        </Link>

        <Link
          to="/pt/trending"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <TrendIcon />
          </div>
          <span>Trending</span>
        </Link>

        <Link
          to="/pt/hashtags"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <HashtagIcon />
          </div>
          <span>Hashtags</span>
        </Link>
      </nav>

      <hr
        style={{ border: "none", height: 1, background: "#DBE2EA", margin: 20 }}
      />

      <Stories stories={stories} />

      <hr
        style={{ border: "none", height: 1, background: "#DBE2EA", margin: 20 }}
      />

      <div className="navigation-bottom">
        <div className="navigation-bottom-social">
          <a href="https://www.facebook.com/nizamapppt" target="_blank">
            <FacebookIcon height={40} round />
          </a>

          <a href="https://www.instagram.com/nizam.app.pt" target="_blank">
            <InstagramIcon size={40} round />
          </a>
        </div>

        <div className="nav-static-links">
          <Link to="/pt/quem-somos" target="_blank">
            Quem Somos
          </Link>
          <Link to="/pt/termos-de-servico" target="_blank">
            Termos de Serviço
          </Link>
          <Link to="/pt/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </Link>
        </div>
      </div>

      <a href="https://t.me/nizamapppt" target="_blank" className="cta">
        Assine nosso Telegram <TelegramIcon size={32} round />
      </a>

      <p className="nav-made-with">
        © 2020 Nizam | Feito por amor a <MadeWithIcon width={27} /> pela Taha
        Brasil
      </p>
    </aside>
  )
}

export default Navigation
