import React, { useState } from "react"
import { Link } from "gatsby"

import "./Header.css"

import MenuIcon from "../images/icons/Menu"
import SearchIcon from "../images/icons/Search"

import nizamLogo from "../images/nizam.png"

const Header = ({
  toggleMenu,
  setToggleMenu,
  toggleSearch,
  setToggleSearch,
}) => {
  return (
    <header>
      <button onClick={() => setToggleMenu(!toggleMenu)}>
        <MenuIcon />
      </button>
      <Link to="/pt">
        <img src={nizamLogo} alt="Nizam" height={37} />
      </Link>
      <button onClick={() => setToggleSearch(!toggleSearch)}>
        <SearchIcon />
      </button>
    </header>
  )
}

export default Header
